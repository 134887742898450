<template>
  <v-container fluid>
    <v-card class="mx-auto">
      <v-card-text>
        <v-simple-table>
          <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="item in publications" v-bind:key="item.id">
            <td>{{ item.type.displayName }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.metadata.status }}</td>
            <td>
              <v-btn @click="goToPublicationDetails(item)" text>
              <v-icon left>
                mdi-pencil
              </v-icon>View Details
              </v-btn>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "MyGoals",
  computed: {
    ...mapGetters('publicationUnitGoal', {
      goals: 'myGoals'
    }),
    publications() {
      return this.goals.map(g => g.publications).flat();
    }
  },
  methods: {
    goToPublicationDetails(publication) {
      this.$router.push({ name: 'se2a-publication-detail', params: { publicationId: publication.id }})
    },
  }
}
</script>

<style scoped>

</style>
